// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ministry-favorites-js": () => import("./../../../src/pages/ministry-favorites.js" /* webpackChunkName: "component---src-pages-ministry-favorites-js" */),
  "component---src-pages-volunteers-js": () => import("./../../../src/pages/volunteers.js" /* webpackChunkName: "component---src-pages-volunteers-js" */)
}

